import React, { Fragment } from 'react'

import Locketnew from "../images/locket-new.jpg";
import Hansh from "../images/hansh.jpg";
import Butterfly from "../images/butterfly.jpg";
import omm from "../images/omm.jpg";

import Radhakrishna from "../images/RADHA KRISHNA.jpg";
import Lordshivlingam from "../images/lord SHIV LINGA.jpg";
import Locketmina from "../images/locket new.jpg";
import Locketdesign from "../images/LOCKET DESIGN.jpg";
import Timifishgold from "../images/TIMIFISH-GOLD.jpg";
import Butterflylove from "../images/Butterfly love design.jpg";
import Lovedesign from "../images/LOVE DESIGN.jpg";
import Leafdesign from "../images/Leaf.jpg";
import Leaflocket from "../images/LEAF GOLD DESIGN.jpg";
import Mayurlocket from "../images/mayurlocket.jpg";
import lOCKETLEAFNEW from "../images/locket-newLRAF.jpg"
import radhakrishnanew from "../images/RADHAKRISHNAL.jpg"
import Locket7leaf from "../images/7 leaf locket.jpg";
import Lovecirclelocket from "../images/love circle locket.jpg";
import Ganeshlocket from "../images/Ganesh Locket.jpg";
import Circleflower from "../images/CIRCLE FLOWER LOCKET.jpg";
import Krishnalocket from "../images/KRISHNA LOCKET.jpg";
import Boatlocket from "../images/boat.jpg";
import Latestdesign from "../images/latest-design.jpg";
import Lovedesignlocket from "../images/LOVEGOLD LOCKET.jpg"
import GaneshLocket from "../images/Ganesh D.jpg"
import ReactWhatsapp from "react-whatsapp";
import Circlelocketdesign from "../images/circle locket.jpg";
import Lordshiv from "../images/lord-shiv.jpg";
import ElephantSur from "../images/ELEPHENT.jpg";
import loveengage from "../images/Love Locket Design New.jpg";
import Hunumanlocket from "../images/Hunuman Locket.jpg";
import Locket786 from "../images/786 Locket.jpg";
import LuxuryLocket from "../images/Luxury Locket.jpg";
import OmmLocket from "../images/Omm Locket.jpg";
import LeafLocket from "../images/leaf with lord.jpg"
import Baazbird from "../images/Baaz Bird.jpg";
import FlowerLocket from "../images/circle flower.jpg";
import BeerHunumanLocket from "../images/Hunuman Beer.jpg";
import Flowerlocketnew from "../images/Flower Locket New.jpg"
import Fishlocket from "../images/fish locket.jpg"
import JharbatiLocket from "../images/JHARBATI GOLD LOCKET.jpg";
import Locketdesignnew from "../images/Lacket design.jpg";
import minalocket from "../images/minawithgoldlocket.jpg";
import Locketnewd from "../images/Locket New D.jpg";
import LoveNet from "../images/love- net.jpg";
import LovewithTrishul from "../images/love with trishul.jpg";
import Mayureithminalocket from "../images/Mayur Locket mina.jpg"
import Flowerlocketd from "../images/Flower Design l.jpg"
import LordKrishna from "../images/Lord Krishna.jpg";
import LordShivamahadeb from "../images/Baba bholenath.jpg";
import swastiklocket from "../images/swasti.jpg"



function Locketpage() {
    return (
        <div>
          
    
        
            <div className="album py-0">
                <div className="container">
                    <div className="row">

                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='Lockek2879'>
                                        <div className="card">
                                            <img src={Locketnew} className="card-img-top" alt="Locketnew" />
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">Locket</h5>
                                                <p className="card-text">Weight: 2.879</p>
                                                <p className="card-text">ID No.</p>

                                                <div className="d-flex justify-content-between align-items-center mb-0">
                                                    <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                    <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                                </div>


                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='Locket3.200'>
                                        <div className="card">
                                            <img src={Hansh} className="card-img-top" alt="Hansh" />
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">Locket</h5>
                                                <p className="card-text">Weight: 3.200</p>
                                                <p className="card-text">ID No..</p>
                                                <div className="d-flex justify-content-between align-items-center mb-0">
                                                    <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                    <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                        <img src={Butterfly} className="card-img-top" alt="Butterfly" />
                                        <div className="card-body">
                                            <h5 className="card-title mb-1">Locket Butterfly</h5>
                                            <p className="card-text">Weight: 3.360</p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <div className="card">
                                        <img src={omm} className="card-img-top" alt="omm" />
                                        <div className="card-body">
                                            <h5 className="card-title mb-1" >Locker Omm</h5>
                                            <p className="card-text">Weight: 0.818</p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='#'>
                                        <div className="card">
                                            <img src={Radhakrishna} className="card-img-top" alt="Radhakrishna" />
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">Radha Krishna</h5>
                                                <p className="card-text">Weight: 4.095</p>
                                                <p className="card-text">ID No.</p>

                                                <div className="d-flex justify-content-between align-items-center mb-0">
                                                    <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                    <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                                </div>


                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='#'>
                                        <div className="card">
                                            <img src={Lordshivlingam} className="card-img-top" alt="Lordshivlingam" />
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">Shiv Langa</h5>
                                                <p className="card-text">Weight: 3.240</p>
                                                <p className="card-text">ID No..</p>
                                                <div className="d-flex justify-content-between align-items-center mb-0">
                                                    <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                    <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                        <img src={Locketmina} className="card-img-top" alt="Locketmina" />
                                        <div className="card-body">
                                            <h5 className="card-title mb-1">Locket Mina</h5>
                                            <p className="card-text">Weight: 4.370</p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <div className="card">
                                        <img src={Locketdesign} className="card-img-top" alt="Locketdesign" />
                                        <div className="card-body">
                                            <h5 className="card-title mb-1" >Locker Design</h5>
                                            <p className="card-text">Weight: 1.892</p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            

            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <div className="card">
                                        <img src={Timifishgold} className="card-img-top" alt="Timifishgold" />
                                        <div className="card-body">
                                            <h5 style={{ color: "black" }} className="card-title mb-1">Timi Fish </h5>
                                            <p className="card-text">Weight: 0.562</p>
                                            <p className="card-text">ID No.</p>

                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>


                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-6  mt-3">

                                    <div className="card">
                                        <img src={Butterflylove} className="card-img-top" alt="Butterflylove" />
                                        <div className="card-body">
                                            <h5 style={{ color: "black" }} className="card-title mb-1">Butterfly Love </h5>
                                            <p className="card-text">Weight: 3.619</p>
                                            <p className="card-text">ID No..</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-0">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                        <img src={Lovedesign} className="card-img-top" alt="Lovedesign" />
                                        <div className="card-body">
                                            <h5 className="card-title mb-1">Love Design</h5>
                                            <p className="card-text">Weight: 0.780</p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <div className="card">
                                        <img src={Leafdesign} className="card-img-top" alt="Leafdesign" />
                                        <div className="card-body">
                                            <h5 className="card-title mb-1" >Leaf Design</h5>
                                            <p className="card-text">Weight: 0.950</p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>





            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                        <img src={Leaflocket} className="card-img-top" alt="Leaflocket" />
                                        <div className="card-body">
                                            <h5 style={{ color: "black" }} className="card-title mb-1">Leaf Locket</h5>
                                            <p className="card-text">Weight: 1.105</p>
                                            <p className="card-text">ID No.</p>

                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>


                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-6  mt-3">

                                    <div className="card">
                                        <img src={Mayurlocket} className="card-img-top" alt="Mayurlocket" />
                                        <div className="card-body">
                                            <h5 style={{ color: "black" }} className="card-title mb-1">Mayur Locket </h5>
                                            <p className="card-text">Weight: 1.040</p>
                                            <p className="card-text">ID No..</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-0">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                        <img src={lOCKETLEAFNEW} className="card-img-top" alt="lOCKETLEAFNEW" />
                                        <div className="card-body">
                                            <h5 className="card-title mb-1">Locket Design</h5>
                                            <p className="card-text">Weight: 1.105</p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <div className="card">
                                        <img src={radhakrishnanew} className="card-img-top" alt="radhakrishnanew" />
                                        <div className="card-body">
                                            <h5 className="card-title mb-1" >Radha Krishna </h5>
                                            <p className="card-text">Weight: 1.125</p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                        <img src={Locket7leaf} className="card-img-top" alt="Leaflocket" />
                                        <div className="card-body">
                                            <h5 style={{ color: "black" }} className="card-title mb-1">Leaf Locket</h5>
                                            <p className="card-text">Weight: 1.080</p>
                                            <p className="card-text">ID No.</p>

                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>


                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-6  mt-3">

                                    <div className="card">
                                        <img src={Lovecirclelocket} className="card-img-top" alt="Lovecirclelocket" />
                                        <div className="card-body">
                                            <h5 style={{ color: "black" }} className="card-title mb-1">Love Design </h5>
                                            <p className="card-text">Weight: 1.462</p>
                                            <p className="card-text">ID No..</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-0">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                        <img src={Ganeshlocket} className="card-img-top" alt="Ganeshlocket" />
                                        <div className="card-body">
                                            <h5 className="card-title mb-1">Ganesh Locket</h5>
                                            <p className="card-text">Weight: 2.126</p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <div className="card">
                                        <img src={Circleflower} className="card-img-top" alt="Circleflower" />
                                        <div className="card-body">
                                            <h5 className="card-title mb-1" >Flower Locket </h5>
                                            <p className="card-text">Weight: 2.100</p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                        <img src={Krishnalocket} className="card-img-top" alt="Krishnalocket" />
                                        <div className="card-body">
                                            <h5 style={{ color: "black" }} className="card-title mb-1">Kishna Locket</h5>
                                            <p className="card-text">Weight: 2.758</p>
                                            <p className="card-text">ID No.</p>

                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>


                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-6  mt-3">

                                    <div className="card">
                                        <img src={Boatlocket} className="card-img-top" alt="Boatlocket" />
                                        <div className="card-body">
                                            <h5 style={{ color: "black" }} className="card-title mb-1">Boat Locket </h5>
                                            <p className="card-text">Weight: 1.512</p>
                                            <p className="card-text">ID No..</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-0">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                        <img src={Latestdesign} className="card-img-top" alt="Latestdesign" />
                                        <div className="card-body">
                                            <h5 className="card-title mb-1">Latest Design</h5>
                                            <p className="card-text">Weight: 1.230</p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <div className="card">
                                        <img src={Lovedesignlocket} className="card-img-top" alt="Lovedesign" />
                                        <div className="card-body">
                                            <h5 className="card-title mb-1" >Love Locket </h5>
                                            <p className="card-text">Weight: 0.710</p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                        <img src={GaneshLocket} className="card-img-top" alt="GaneshLocket" />
                                        <div className="card-body">
                                            <h5 style={{ color: "black" }} className="card-title mb-1">Ganesh Locket</h5>
                                            <p className="card-text">Weight: 3.371</p>
                                            <p className="card-text">ID No.</p>

                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>


                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-6  mt-3">

                                    <div className="card">
                                        <img src={Circlelocketdesign} className="card-img-top" alt="Circlelocketdesign" />
                                        <div className="card-body">
                                            <h5 style={{ color: "black" }} className="card-title mb-1">Circle Locket </h5>
                                            <p className="card-text">Weight: 1.666</p>
                                            <p className="card-text">ID No..</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-0">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                        <img src={Lordshiv} className="card-img-top" alt="Lordshiva" />
                                        <div className="card-body">
                                            <h5 className="card-title mb-1">Lord Shiva</h5>
                                            <p className="card-text">Weight: 4.045</p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <div className="card">
                                        <img src={ElephantSur} className="card-img-top" alt="Elephant sur" />
                                        <div className="card-body">
                                            <h5 className="card-title mb-1" >Elephant sur </h5>
                                            <p className="card-text">Weight: 13.050</p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                        <img src={loveengage} className="card-img-top" alt="love locket" />
                                        <div className="card-body">
                                            <h5 style={{ color: "black" }} className="card-title mb-1">Love Engage</h5>
                                            <p className="card-text">Weight: 1.162</p>
                                            <p className="card-text">ID No.</p>

                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>


                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-6  mt-3">

                                    <div className="card">
                                        <img src={Hunumanlocket} className="card-img-top" alt="Hunumanlocket" />
                                        <div className="card-body">
                                            <h5 style={{ color: "black" }} className="card-title mb-1">Hunuman </h5>
                                            <p className="card-text">Weight: 3.350</p>
                                            <p className="card-text">ID No..</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-0">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                        <img src={Locket786} className="card-img-top" alt="786" />
                                        <div className="card-body">
                                            <h5 className="card-title mb-1">786 Design</h5>
                                            <p className="card-text">Weight: 0.668</p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <div className="card">
                                        <img src={LuxuryLocket} className="card-img-top" alt="Luxury Locket MB Dhar and son jewellers" />
                                        <div className="card-body">
                                            <h5 className="card-title mb-1" >Luxury Locket </h5>
                                            <p className="card-text">Weight: 4.330</p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                        <img src={OmmLocket} className="card-img-top" alt="Omm Locket" />
                                        <div className="card-body">
                                            <h5 style={{ color: "black" }} className="card-title mb-1">Omm Locket</h5>
                                            <p className="card-text">Weight: 0.852</p>
                                            <p className="card-text">ID No.</p>

                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>


                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-6  mt-3">

                                    <div className="card">
                                        <img src={LeafLocket} className="card-img-top" alt="Leaf Locket" />
                                        <div className="card-body">
                                            <h5 style={{ color: "black" }} className="card-title mb-1">Leaf Locket</h5>
                                            <p className="card-text">Weight: 3.660</p>
                                            <p className="card-text">ID No..</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-0">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                        <img src={Baazbird} className="card-img-top" alt="Baazbird" />
                                        <div className="card-body">
                                            <h5 className="card-title mb-1">Baaz Bird</h5>
                                            <p className="card-text">Weight: 2.551</p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <div className="card">
                                        <img src={FlowerLocket} className="card-img-top" alt="Flower Locket" />
                                        <div className="card-body">
                                            <h5 className="card-title mb-1" >Flower Locket </h5>
                                            <p className="card-text">Weight: 4.330</p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                        <img src={BeerHunumanLocket} className="card-img-top" alt="Beer Hunuman Locket" />
                                        <div className="card-body">
                                            <h5 style={{ color: "black" }} className="card-title mb-1">Hunuman </h5>
                                            <p className="card-text">Weight: 3.830</p>
                                            <p className="card-text">ID No.</p>

                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>


                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-6  mt-3">

                                    <div className="card">
                                        <img src={Flowerlocketnew} className="card-img-top" alt="Flowerlocketnew" />
                                        <div className="card-body">
                                            <h5 style={{ color: "black" }} className="card-title mb-1">Flower Locket</h5>
                                            <p className="card-text">Weight: 5.130</p>
                                            <p className="card-text">ID No..</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-0">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                        <img src={Fishlocket} className="card-img-top" alt="Fishlocket" />
                                        <div className="card-body">
                                            <h5 className="card-title mb-1">Fish Locket</h5>
                                            <p className="card-text">Weight: 2.032</p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <div className="card">
                                        <img src={JharbatiLocket} className="card-img-top" alt="Jharbati Locket" />
                                        <div className="card-body">
                                            <h5 className="card-title mb-1" >Jharbati  </h5>
                                            <p className="card-text">Weight: 2.805</p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                        <img src={Locketdesignnew} className="card-img-top" alt="Locketdesignnew" />
                                        <div className="card-body">
                                            <h5 style={{ color: "black" }} className="card-title mb-1">Locket </h5>
                                            <p className="card-text">Weight: 1.845</p>
                                            <p className="card-text">ID No.</p>

                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>


                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-6  mt-3">

                                    <div className="card">
                                        <img src={minalocket} className="card-img-top" alt="Mina with Gold Locket" />
                                        <div className="card-body">
                                            <h5 style={{ color: "black" }} className="card-title mb-1">Latest Design</h5>
                                            <p className="card-text">Weight: 1.679</p>
                                            <p className="card-text">ID No..</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-0">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                        <img src={Locketnewd} className="card-img-top" alt="newdesign" />
                                        <div className="card-body">
                                            <h5 className="card-title mb-1"> Locket New</h5>
                                            <p className="card-text">Weight: 2.032</p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <div className="card">
                                        <img src={LoveNet} className="card-img-top" alt="Love Net " />
                                        <div className="card-body">
                                            <h5 className="card-title mb-1" >Love Net </h5>
                                            <p className="card-text">Weight: 5.424</p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                        <img src={LovewithTrishul} className="card-img-top" alt="LovewithTrishul" />
                                        <div className="card-body">
                                            <h5 style={{ color: "black" }} className="card-title mb-1">Love Locket</h5>
                                            <p className="card-text">Weight: 0.645</p>
                                            <p className="card-text">ID No.</p>

                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>


                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-6  mt-3">

                                    <div className="card">
                                        <img src={Mayureithminalocket} className="card-img-top" alt="Mayurwithmina Locket" />
                                        <div className="card-body">
                                            <h5 style={{ color: "black" }} className="card-title mb-1">Mayur Locket</h5>
                                            <p className="card-text">Weight: 14.915</p>
                                            <p className="card-text">ID No..</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-0">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                        <img src={Flowerlocketd} className="card-img-top" alt="Flowerlocket" />
                                        <div className="card-body">
                                            <h5 className="card-title mb-1"> Locket</h5>
                                            <p className="card-text">Weight: 4.666</p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <div className="card">
                                        <img src={LordKrishna} className="card-img-top" alt="Lord Krishna" />
                                        <div className="card-body">
                                            <h5 className="card-title mb-1" >Lord Krishna </h5>
                                            <p className="card-text">Weight: 3.960</p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                        <img src={LordShivamahadeb} className="card-img-top" alt="Lord Shiva" />
                                        <div className="card-body">
                                            <h5 style={{ color: "black" }} className="card-title mb-1">Lord Shiva</h5>
                                            <p className="card-text">Weight: 3.640</p>
                                            <p className="card-text">ID No.</p>

                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>


                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-6  mt-3">

                                    <div className="card">
                                        <img src={swastiklocket} className="card-img-top" alt="swastiklocket" />
                                        <div className="card-body">
                                            <h5 style={{ color: "black" }} className="card-title mb-1">Swastik Locket</h5>
                                            <p className="card-text">Weight: 0.725</p>
                                            <p className="card-text">ID No..</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <br />

            <br />
        </div>
    )
}

export default Locketpage