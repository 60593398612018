import React from 'react'
import Bestastrologyberhampore from "../images/bestastroberhampore.jpg";
import Header from "../inc/Header"

function Astro() {
    return (
        <div>
            <Header />
            <br />
            <br />
            <br />
            <br />
            <br />

            <br />
            <br />
            <br />
            <div className="album py-0">
                <div className="container">
                  
                        <div className="card mb-3" >
                            <div className="row g-0">
                                <div className="col-md-5">
                                    <img src={Bestastrologyberhampore} className="img-fluid rounded-start" alt="Bestastrologyberhampore" />
                                </div>
                                <div className="col-md-7">
                                    <div className="card-body">
                                        <h4 className="card-title mb-1"><b>Shree Ajit Sastri <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-check2-circle" viewBox="0 0 16 16">
  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0"/>
  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"/>
</svg></b></h4>
                                        <h6 className="card-text mb-5">Gold Medalist</h6>
                                        <h5 className="card-text"><small className="text-muted"> জ্যোতিষ বিভাগ </small></h5>
                                        <h6 style={{color:"maroon"}} className="card-text mb-4 "> প্রতি শুক্রবার দুফুর ১২ টা থেকে সন্ধে ৬ টা </h6>
                                        <a style={{color:"white"}} className='buttonastro' href='tel: +91 9046209114'>Call Us Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
              
            </div>
            <br />
            <br />
        </div>
    )
}

export default Astro