import React  from 'react'
import ScrollToTop from "react-scroll-to-top";
import {Link} from "react-router-dom";
import ReactWhatsapp from "react-whatsapp";
import Passatimifish from "../images/Passa Timi Fish.jpg";
import passashanka from "../images/Sankha Passa.jpg";
import Passalove from "../images/Passa Love.jpg";

import Passapakha from "../images/Passa pakha.jpg";

import Timifishpassa from "../images/Timi Fish passa.jpg";
import Passaghanta from "../images/Passa Ghanta.jpg";
import Passaflowercircle from "../images/passa Flower circle.jpg";
import Passadul from "../images/Passa dul.jpg";


import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import Passadesign from "../images/passa design.jpg";
import Passad from "../images/Passa d.jpg";
import Passacircle from "../images/Passa Circle.jpg";
import Passacircle2 from "../images/passa circle 2.jpg";

import lovepassa from  "../images/Love Passa 2.jpg";
import Kulopassa from "../images/Kulo Pssa.jpg";

import Passaaghanta from "../images/ghanta.jpg";
import Passabestdesign from "../images/passa best design.jpg";
import Passanewphoto from "../images/Passa new d.jpg"
import Lovepassa  from "../images/Love Passa.jpg";
import Passabutterfly from "../images/Passa Butterfly.jpg";
import Passacirlerakhidesign from "../images/Passa flower circle rakhi.jpg"
import Passajhumka from "../images/Passa jhumka.jpg";
import Passalatest from "../images/Passa Latest.jpg";
import Passawitmina from "../images/Passa mina.jpg";
import Passakites from "../images/Passa kites.jpg";


import Header from "../inc/Header";

function Passa() {
    return (
        <div>
          
    <Header/>
    <br/>
    <br/>
    <br/>
    <br/>
    <ScrollToTop smooth top="100" color="maroon"/>
    <div className='colorbacklockt'>
                <div className="album py-1">
                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Passa</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        
            <div className="album py-0">
                <div className="container">
                    <div className="row">

                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                   
                                        <div className="card">
                                        <Zoom>
                                            <img src={Passatimifish} className="card-img-top" alt="Passatimifish"/>
                                            </Zoom>
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">Passa </h5>
                                                <p className="card-text">Weight: </p>
                                                <p className="card-text">ID No.</p>

                                                <div className="d-flex justify-content-between align-items-center mb-0">
                                                    <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                    <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                                <div className="col-md-6  mt-3">
                           
                                        <div className="card">
                                        <Zoom>
                                            <img src={passashanka} className="card-img-top" alt="passashanka"/>
                                            </Zoom>
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">Passa </h5>
                                                <p className="card-text">Weight: </p>
                                                <p className="card-text">ID No..</p>
                                                <div className="d-flex justify-content-between align-items-center mb-0">
                                                    <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                    <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                                </div>
                                            </div>
                                        </div>
                                    
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                    <Zoom>
                                        <img src={Passalove} className="card-img-top" alt="Passalove"/>
                                        </Zoom>
                                        <div className="card-body">
                                            <h5 className="card-title mb-1">Passa </h5>
                                            <p className="card-text">Weight: </p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <div className="card">
                                    <Zoom>
                                        <img src={Passapakha} className="card-img-top" alt="Passapakha"/>
                                        </Zoom>
                                        <div className="card-body">
                                            <h5 className="card-title mb-1" >Passa                                       </h5>
                                            <p className="card-text">Weight: </p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                  
                                        <div className="card">
                                        <Zoom>
                                            <img id="myimage" src={Timifishpassa} className="card-img-top" alt="Timifishpassa"/>
                                            </Zoom>
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">Passa </h5>
                                                <p className="card-text">Weight: </p>
                                                <p className="card-text">ID No.</p>

                                                <div className="d-flex justify-content-between align-items-center mb-0">
                                                    <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                    <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                                </div>


                                            </div>
                                        </div>
                                    
                                </div>
                                <div className="col-md-6  mt-3">
                               
                                        <div className="card">
                                        <Zoom>
                                            <img src={Passaghanta} className="card-img-top" alt="Passaghanta"/>
                                            </Zoom>
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">Passa </h5>
                                                <p className="card-text">Weight: </p>
                                                <p className="card-text">ID No..</p>
                                                <div className="d-flex justify-content-between align-items-center mb-0">
                                                    <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                    <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                                </div>
                                            </div>
                                        </div>
                                  
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                    <Zoom>
                                        <img src={Passaflowercircle} className="card-img-top" alt="Passaflowercircle"/>
                                        </Zoom>
                                        <div className="card-body">
                                            <h5 className="card-title mb-1">Passa  </h5>
                                            <p className="card-text">Weight: </p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <div className="card">
                                    <Zoom>
                                        <img src={Passadul} className="card-img-top" alt="Passadul"/>
                                        </Zoom>
                                        <div className="card-body">
                                            <h5 className="card-title mb-1" >Passa </h5>
                                            <p className="card-text">Weight: </p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div> 

            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                  
                                        <div className="card">
                                        <Zoom>
                                            <img id="myimage" src={Passadesign} className="card-img-top" alt="Passadesign"/>
                                            </Zoom>
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">Passa</h5>
                                                <p className="card-text">Weight: </p>
                                                <p className="card-text">ID No.</p>

                                                <div className="d-flex justify-content-between align-items-center mb-0">
                                                    <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                    <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                                </div>


                                            </div>
                                        </div>
                                    
                                </div>
                                <div className="col-md-6  mt-3">
                               
                                        <div className="card">
                                        <Zoom>
                                            <img src={Passad} className="card-img-top" alt="necklace"/>
                                            </Zoom>
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">Passa</h5>
                                                <p className="card-text">Weight: </p>
                                                <p className="card-text">ID No..</p>
                                                <div className="d-flex justify-content-between align-items-center mb-0">
                                                    <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                    <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                                </div>
                                            </div>
                                        </div>
                                  
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                    <Zoom>
                                        <img src={Passacircle} className="card-img-top" alt="Passacircle"/>
                                        </Zoom>
                                        <div className="card-body">
                                            <h5 className="card-title mb-1">Passa </h5>
                                            <p className="card-text">Weight: </p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <div className="card">
                                    <Zoom>
                                        <img src={Passacircle2} className="card-img-top" alt="Passacircle2"/>
                                        </Zoom>
                                        <div className="card-body">
                                            <h5 className="card-title mb-1" >Passa </h5>
                                            <p className="card-text">Weight: </p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div> 

            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                  
                                        <div className="card">
                                        <Zoom>
                                            <img id="myimage" src={lovepassa} className="card-img-top" alt="lovepassa"/>
                                            </Zoom>
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">Passa</h5>
                                                <p className="card-text">Weight: </p>
                                                <p className="card-text">ID No.</p>

                                                <div className="d-flex justify-content-between align-items-center mb-0">
                                                    <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                    <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                                </div>


                                            </div>
                                        </div>
                                    
                                </div>
                                <div className="col-md-6  mt-3">
                               
                                        <div className="card">
                                        <Zoom>
                                            <img src={Kulopassa} className="card-img-top" alt="Kulopassa"/>
                                            </Zoom>
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">Passa </h5>
                                                <p className="card-text">Weight: </p>
                                                <p className="card-text">ID No..</p>
                                                <div className="d-flex justify-content-between align-items-center mb-0">
                                                    <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                    <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                                </div>
                                            </div>
                                        </div>
                                  
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                    <Zoom>
                                        <img src={Passaaghanta} className="card-img-top" alt="Passaaghanta"/>
                                        </Zoom>
                                        <div className="card-body">
                                            <h5 className="card-title mb-1">Passa  </h5>
                                            <p className="card-text">Weight: </p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <div className="card">
                                    <Zoom>
                                        <img src={Passabestdesign} className="card-img-top" alt="Passabestdesign"/>
                                        </Zoom>
                                        <div className="card-body">
                                            <h5 className="card-title mb-1" >Passa</h5>
                                            <p className="card-text">Weight: </p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div> 

        
            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                  
                                        <div className="card">
                                        <Zoom>
                                            <img id="myimage" src={Passanewphoto} className="card-img-top" alt="lPassanewphoto"/>
                                            </Zoom>
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">Passa</h5>
                                                <p className="card-text">Weight: </p>
                                                <p className="card-text">ID No.</p>

                                                <div className="d-flex justify-content-between align-items-center mb-0">
                                                    <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                    <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                                </div>


                                            </div>
                                        </div>
                                    
                                </div>
                                <div className="col-md-6  mt-3">
                               
                                        <div className="card">
                                        <Zoom>
                                            <img src={Lovepassa} className="card-img-top" alt="Lovepassa"/>
                                            </Zoom>
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">Passa</h5>
                                                <p className="card-text">Weight: </p>
                                                <p className="card-text">ID No..</p>
                                                <div className="d-flex justify-content-between align-items-center mb-0">
                                                    <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                    <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                                </div>
                                            </div>
                                        </div>
                                  
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                    <Zoom>
                                        <img src={Passabutterfly} className="card-img-top" alt="Passabutterfly"/>
                                        </Zoom>
                                        <div className="card-body">
                                            <h5 className="card-title mb-1">Passa </h5>
                                            <p className="card-text">Weight: </p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <div className="card">
                                    <Zoom>
                                        <img src={Passacirlerakhidesign} className="card-img-top" alt="Passacirlerakhidesign"/>
                                        </Zoom>
                                        <div className="card-body">
                                            <h5 className="card-title mb-1" >Passa </h5>
                                            <p className="card-text">Weight: </p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                  
                                        <div className="card">
                                        <Zoom>
                                            <img id="myimage" src={Passajhumka} className="card-img-top" alt="Passajhumka"/>
                                            </Zoom>
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">Passa </h5>
                                                <p className="card-text">Weight: </p>
                                                <p className="card-text">ID No.</p>

                                                <div className="d-flex justify-content-between align-items-center mb-0">
                                                    <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                    <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                                </div>


                                            </div>
                                        </div>
                                    
                                </div>
                                <div className="col-md-6  mt-3">
                               
                                        <div className="card">
                                        <Zoom>
                                            <img src={Passalatest} className="card-img-top" alt="Passalatest"/>
                                            </Zoom>
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">Passa </h5>
                                                <p className="card-text">Weight: </p>
                                                <p className="card-text">ID No..</p>
                                                <div className="d-flex justify-content-between align-items-center mb-0">
                                                    <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                    <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                                </div>
                                            </div>
                                        </div>
                                  
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                    <Zoom>
                                        <img src={Passawitmina} className="card-img-top" alt="Passawitmina"/>
                                        </Zoom>
                                        <div className="card-body">
                                            <h5 className="card-title mb-1">Passa  </h5>
                                            <p className="card-text">Weight: </p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <div className="card">
                                    <Zoom>
                                        <img src={Passakites} className="card-img-top" alt="Passakites"/>
                                        </Zoom>
                                        <div className="card-body">
                                            <h5 className="card-title mb-1" >Passa </h5>
                                            <p className="card-text">Weight: </p>
                                            <p className="card-text">ID No.</p>
                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                                <a className='colorbutton me-2' href='tel: 9434168565'> Book Now</a>
                                                <ReactWhatsapp number="++91 97334 46565" className="colorbuttonwhatsapp" message="I am Interested"> Whatsapp </ReactWhatsapp>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
           
            <br />

            <br />
        </div>
    )
}

export default Passa