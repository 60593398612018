import React from 'react'
import Header from "../inc/Header";
import Slider from "../inc/Slider";
import Neckless from "../images/neckless.jpg";
import hars from "../images/balamb.jpg";
import Bala from "../images/mala.jpg";
import modelling from "../images/modeller.jpg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Harandneckless from "../images/har.jpg";
import Neck from "../images/necjlesshar.jpg";
import Necklessgreen from "../images/necklessgreen.jpg";
import Malagold from "../images/har1.jpg";
import { Link } from "react-router-dom";
import AddAppointment from "../AddAppointment";

import Silck from "../pages/Silck";
import "./Home.css";

import SonarVideo from "../pages/SonarVideo";
import {
  FacebookShareButton, WhatsappShareButton, WhatsappIcon,
  FacebookIcon, TwitterShareButton, EmailShareButton, EmailIcon,

  LinkedinShareButton,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
import Locketpage from './Locketpage';
import Necklacehome from './Necklacehome';


function Home() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <div>
   


      <Header />
      <br />
      <br />
      <br/>
      

      <br />
  
      <Slider />

      <Silck />
      <br />
      <div className="album py-2">

        <div className="container">

          <div className="row">
            <div className="mbdhar">
              <h1><b style={{ color: "maroon" }}>M. B. Dhar And Son Jewellers</b> </h1>
              </div>
              <div className='mbd'>
              <h2>Sonar Sonsar  Episod </h2>
              </div>
            
          </div>
        </div>
      </div>

      <SonarVideo />

      <div className="album py-2">

        <div className="container">

          <div className="row">

            <div className="col-md-4 mt-8" >
              <div className="card mb-4 border-White box shadow">
                <img src={Neckless} className="card-img-top" alt="Neckless" />
                <div className="card-body">

                  <h6 className="card-text"> <b>Seli</b></h6>
                  <p className="card-text">M.B Dhor and Son Jewellers</p>

                  <button type="button"  className="btn btn btn-dark me-2 ">
                    Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                      <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                    </svg>
                  </button>

                  < FacebookShareButton url="https://www.mbdharandsonjewellers.com/" className="me-2" quote={"MB_Dhar_and_Son_Jewellers"} hastag={"#WebApp"}
                  >
                    <FacebookIcon size={37} round={true} />
                  </FacebookShareButton>

                  <  WhatsappShareButton url="https://www.mbdharandsonjewellers.com/" className="me-2" quote={"MB_Dhar_and_Son_Jewellers"} hastag={"#WebApp"}
                  >
                    <WhatsappIcon size={37} round={true} />
                  </ WhatsappShareButton>

                  < TwitterShareButton url="https://www.mbdharandsonjewellers.com/" className="me-2" quote={"MB_Dhar_and_Son_Jewellers"} hastag={"#WebApp"}
                  >
                    <TwitterIcon size={37} round={true} />
                  </TwitterShareButton>
                  <  LinkedinShareButton url="https://www.mbdharandsonjewellers.com/" className="me-2" quote={"MB_Dhar_and_Son_Jewellers"} hastag={"#WebApp"}
                  >
                    <  LinkedinIcon size={37} round={true} />
                  </ LinkedinShareButton>

                  <  EmailShareButton url="https://www.mbdharandsonjewellers.com/" className="me-2" quote={"MB_Dhar_and_Son_Jewellers"} hastag={"#WebApp"}
                  >
                    <  EmailIcon size={37} round={true} />
                  </ EmailShareButton>
                  <div className="d-flex justify-content-between align-items-center">

                  </div>
                </div>
              </div>

            </div>
            <div className="col-md-4 mt-6" >
              <div className="card mb-4 border-white box shadow">
                <Link to="/solidchuri" target="_blank" rel="noopener noreferrer">
                  <img src={hars} className="card-img-top" alt="Mbdhorandsonjewellers" />
                </Link>
                <div className="card-body">

                  <h6 className="card-text"><b>Solid Churi</b></h6>
                  <p className="card-text"> M.B Dhor and Son Jewellers </p>
                  <button type="button" className="btn btn btn-dark me-2">
                    Share  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                      <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                    </svg>
                  </button>
                  < FacebookShareButton url="https://www.mbdharandsonjewellers.com/" className="me-2" quote={"MB_Dhar_and_Son_Jewellers"} hastag={"#WebApp"}
                  >
                    <FacebookIcon size={37} round={true} />
                  </FacebookShareButton>

                  <  WhatsappShareButton url="https://www.mbdharandsonjewellers.com/" className="me-2" quote={"MB_Dhar_and_Son_Jewellers"} hastag={"#WebApp"}
                  >
                    <WhatsappIcon size={37} round={true} />
                  </ WhatsappShareButton>

                  < TwitterShareButton url="https://www.mbdharandsonjewellers.com/" className="me-2" quote={"MB_Dhar_and_Son_Jewellers"} hastag={"#WebApp"}
                  >
                    <TwitterIcon size={37} round={true} />
                  </TwitterShareButton>
                  <  LinkedinShareButton url="https://www.mbdharandsonjewellers.com/" className="me-2" quote={"MB_Dhar_and_Son_Jewellers"} hastag={"#WebApp"}
                  >
                    <  LinkedinIcon size={37} round={true} />
                  </ LinkedinShareButton>

                  <  EmailShareButton url="https://www.mbdharandsonjewellers.com/" className="me-2" quote={"MB_Dhar_and_Son_Jewellers"} hastag={"#WebApp"}
                  >
                    <  EmailIcon size={37} round={true} />
                  </ EmailShareButton>
                  <div className="d-flex justify-content-between align-items-center">


                  </div>
                </div>
              </div>

            </div>
            {/* style={{textAlign: "center"}} */}
            <div className="col-md-4" >
              <div className="card mb-4 border-white box shadow">
                <Link to="/chain" target="_blank" rel="noopener noreferrer" >
                  <img src={Bala} className="card-img-top" alt="Bala" />
                </Link>
                <div className="card-body">
                  <h6 className="card-text"><b>Chain</b></h6>
                  <p className="card-text">M.B Dhor and Son Jewellers</p>

                  <button type="button" className="btn btn btn-dark me-2">
                    Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                      <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                    </svg>
                  </button>

                  < FacebookShareButton url="https://www.mbdharandsonjewellers.com/" className="me-2" quote={"MB_Dhar_and_Son_Jewellers"} hastag={"#mbdharandsonjewellers"}
                  >
                    <FacebookIcon size={37} round={true} />
                  </FacebookShareButton>

                  <  WhatsappShareButton url="https://www.mbdharandsonjewellers.com/" className="me-2" quote={"MB_Dhar_and_Son_Jewellers"} hastag={"#WebApp"}
                  >
                    <WhatsappIcon size={37} round={true} />
                  </ WhatsappShareButton>

                  < TwitterShareButton url="https://www.mbdharandsonjewellers.com/" className="me-2" quote={"MB_Dhar_and_Son_Jewellers"} hastag={"#WebApp"}
                  >
                    <TwitterIcon size={37} round={true} />
                  </TwitterShareButton>
                  <  LinkedinShareButton url="https://www.mbdharandsonjewellers.com/" className="me-2" quote={"MB_Dhar_and_Son_Jewellers"} hastag={"#WebApp"}
                  >
                    <  LinkedinIcon size={37} round={true} />
                  </ LinkedinShareButton>

                  <  EmailShareButton url="https://www.mbdharandsonjewellers.com/" className="me-2" quote={"MB_Dhar_and_Son_Jewellers"} hastag={"#WebApp"}
                  >
                    <  EmailIcon size={37} round={true} />
                  </ EmailShareButton>
                  <div className="d-flex justify-content-between align-items-center">




                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>


      <marquee>
        <img src={modelling} className="img-fluid" alt="mbdhorandsonjewellermodel" /></marquee>


      <div className="containerse">
        <center>
          <div className="sentancestyle">
            <h1><b>M.B. DHAR AND SON JEWELLERS</b></h1><br />
          </div>
          <div className="sentancestyles">
            <h3>Best Jewellery Collections</h3>
          </div>
        </center>
      </div>

{/* 
      <div className="appointmentbg">
        <div className="album py-4">

          <div className="container">

            <div className="row">

              <div className="col-md-4" >
                <button type="button" className="btn btn-outline-danger btn-sm " data-bs-toggle="modal" data-bs-target="#exampleModal">
                  Book Appointment
                </button>

                <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">M.B. DHAR AND SON JEWELLERS</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                        <AddAppointment />
                      </div>
                      <div className="modal-footer">

                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}


      <div className="album py-3">

        <div className="container">

          <div className="row">
            <h2 style={{ color: "maroon" }}>Best Collection </h2>
          </div>
        </div>
      </div>

      <div style={{ color: "Gray", padding: "14px;", margin: 10 }} className="fixed-bottom "> <a href="tel: 9733446565" className="homebuttoncolor" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-phone" viewBox="0 0 16 16">
        <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
        <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
      </svg> Call Us <div className="spinner-grow text-light spinner-grow-sm" role="status">
          <span className="visually-hidden"> Loading...</span>
        </div> </a></div>


      <div className="album py-2">
        <div className="container">
          <div className="row">

            <Carousel responsive={responsive}>
              <div className="card border mb-3 me-3" >
                <img className="card-img-top" src={Necklessgreen} alt="M_b_dhor_and_son_jewellers" />
                <div className="card-body text">
                  <center>
                    <h6 className="card-title mb-0"><b>Seli </b></h6>
                    <p className="card-text"><small>By M.B. Dhar and Son Jewellers </small></p>
                  </center>
                </div>
              </div>
              <div className="card border mb-3 me-3" >
                <img className="card-img-top" src={Neck} alt="M_b_dhor_and_son_jewellers" />
                <div className="card-body text">
                  <center>
                    <h6 className="card-title mb-0"><b>Necklace </b></h6>
                    <p className="card-text"><small>By M.B. Dhar and Son Jewellers</small></p>
                  </center>
                </div>
              </div>
              <div className="card border mb-3 me-3" >
                <img className="card-img-top" src={Malagold} alt="M_b_dhor_and_son_jewellers" />
                <div className="card-body text">
                  <center>
                    <h6 className="card-title mb-0"><b>Seli</b></h6>
                    <p className="card-text"><small>By M.B. Dhar and Son Jewellers</small></p>
                  </center>
                </div>
              </div>



              <div className="card border mb-3 me-3" >
                <img className="card-img-top" src={Harandneckless} alt="M_b_dhor_and_son_jewellers" />
                <div className="card-body text">
                  <center>
                    <h6 className="card-title mb-0"><b>Necklace </b></h6>
                    <p className="card-text"><small>By M.B. Dhar and Son Jewellers</small></p>
                  </center>
                </div>
              </div>
            </Carousel>
          </div>
        </div>

      </div>
      <br/>
      <div className="album py-0">
      <div className="container">
        <div className='goldcolor'>
        <h1 className='mb-1' >Explore <b >Necklace</b></h1>
        <p>Trending necklace design available</p>
        </div>
      </div>
      </div>
      <Necklacehome/>
      <div className="album py-0">
      <div className="container">
        <div className='goldcolor'>
        <h1 className='mb-1' >Explore <b >Locket</b></h1>
        <p>Trending locket design available</p>
        </div>
      </div>
      </div>
      <Locketpage/>
    
      <br />
    </div>




  )
}

export default Home
