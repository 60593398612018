import React from 'react'
import Slide from "../images/slide1.jpg";
import SlideS from "../images/MBSLIDE.jpg";
import Main from "../images/MAINADS.jpg";
import mbdharastro from "../images/mb_astrology.jpg";

function Slider() {
  return (
    <div>
    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>


  </div>
  <div className="carousel-inner">
     <div className="carousel-item active">
      <img src={Main} className="d-block w-100" alt="MB-DHOR-AND-JEWELLERS"/>
    </div>
    <div className="carousel-item">
      <img src={mbdharastro} className="d-block w-100" alt="MB-DHOR-AND-JEWELLERS"/>
    </div>
    <div className="carousel-item">
      <img src={Slide} className="d-block w-100" alt="MB-DHOR-AND-JEWELLERS"/>
    </div>
    <div className="carousel-item">
      <img src={SlideS} className="d-block w-100" alt="MB-DHOR-AND-JEWELLERS"/>
    </div>
   
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
    </div>
  )
}

export default Slider
