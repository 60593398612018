import React from 'react'
import Header from "../inc/Header";
import ScrollToTop from "react-scroll-to-top";
import Locketpage from './Locketpage';
import {Link} from "react-router-dom";

function Lockethome() {
  return (
    <div>
          <Header/>
          <br/>
          <br/>
          <br/>
          <br/>
          <div className='colorbacklockt'>
                <div className="album py-1">
                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Locket</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
          <ScrollToTop smooth top="100" color="maroon" />
          <Locketpage/>
    </div>
  )
}

export default Lockethome