import React from 'react'
import Header from "../inc/Header";
import Lockate from "../images/hansh.jpg";
import halmark from "../images/halmark.png";
import {Link} from "react-router-dom";
import ReactWhatsapp from "react-whatsapp";
import ScrollToTop from "react-scroll-to-top";
function Lockethans() {
    return (
        <div>
            <Header />
            <br />
            <br />
            <br />
            <br />
            <ScrollToTop smooth top="100" color="maroon" />
            <div className='backcolor'>
            <div className="album py-1">
                <div className="container mt-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li style={{color:"white"}} className="breadcrumb-item"><Link style={{color:"white"}} to="/" href="#">Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/Locketpage" style={{color:"white"}} href="#">Back</Link></li>
                            <li className="breadcrumb-item active" style={{color:"white"}} aria-current="page">Locket</li>
                        </ol>
                    </nav>
                </div>
            </div>
            </div>
            <br />

            <div className="album py-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mt-3 me-3">
                            <div className="btn-grad13 mb-0" type="submit">100% CERTIFICATE - LIFETIME EXCHANGE</div>
                            <div className='card'>
                            <img src={Lockate} className="card-img-top mb-3" alt="Lockate" />
                            <div className="card-body">
                                <h5 className="card-title mb-3">CERTIFICATE OF AUTHENTICITY</h5>
                                <p style={{ color: "maroon" }} className="card-text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                </svg> 5 Star Promise</p>
                                <p style={{ color: "maroon" }} className="card-text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cart-check" viewBox="0 0 16 16">
                                    <path d="M11.354 6.354a.5.5 0 0 0-.708-.708L8 8.293 6.854 7.146a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z" />
                                    <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1zm3.915 10L3.102 4h10.796l-1.313 7zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0m7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                                </svg> Easy Exchange</p>
                                <p style={{ color: "maroon" }} className="card-text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-square" viewBox="0 0 16 16">
                                    <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5z" />
                                    <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0" />
                                </svg> BIS Hallmarked Pure Gold</p>


                            </div>
                        </div>
                        </div>
                        <div className="col-md-5 mt-3">


                            <div className="card-body">
                                <h5 className="card-title">MADE IN INDIA </h5>
                                <h3 style={{ color: "maroon" }} className="card-text">Lockate Design Hans</h3>
                                <h6 style={{ color: 'grey' }} >SKU: MB-L00087</h6>
                                <br />
                                <h5 className='mb-3'>Weight : 3.200</h5>
                                <h4 style={{ color: "deeppink" }} >M.B DHAR AND SON JEWELLERS Trusted</h4>
                                <img src={halmark} height="100px" width="130px" alt=''/>
                                <br />

                                <br />
                                <div className="d-flex justify-content-between align-items-center">
                                    <a href='tel: 9434168565'>
                                <div  className="btn-gradnav">+91 9434168565</div>
                                </a>
                                <ReactWhatsapp number="+91 9434168565" className="btn-gradnav">WhatsApp Chat</ReactWhatsapp>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <br />
          
        </div>
    )
}

export default Lockethans