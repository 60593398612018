
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
    apiKey: "AIzaSyA8kweecuVC0Cy0r3kzmPvTivzhK3Uk6Os",
    authDomain: "mb-dharand-son.firebaseapp.com",
    projectId: "mb-dharand-son",
    storageBucket: "mb-dharand-son.appspot.com",
    messagingSenderId: "1034184536716",
    appId: "1:1034184536716:web:69e9fd537c89241ae081a9",
    measurementId: "G-7PZF3CWEY4"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;