import React from 'react'
import Header from "../inc/Header";
import ScrollToTop from "react-scroll-to-top";

import {Link} from "react-router-dom";
import Necklacehome from './Necklacehome';


function Necklacepage() {
  return (
    <div>
        <Header/>
        <ScrollToTop smooth top="100" color="maroon" />
        <br/>
        <br/>
        <br/>
        <br/>
        <div className='colorbacklockt'>
                <div className="album py-1">
                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Necklace</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <Necklacehome/>
           
    </div>
  )
}

export default Necklacepage